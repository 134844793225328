

.react-datepicker-wrapper {
    width: 100% !important;
}

.example-custom-input {
    width: 100% !important;
}


