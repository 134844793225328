/* Chap */
@font-face {
  font-family: "Chap";
  src: url(../assets/fonts/chap/Chap-Regular.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Chap";
  src: url(../assets/fonts/chap/Chap-Medium.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Chap";
  src: url(../assets/fonts/chap/Chap-Semibold.otf) format("opentype");
  font-weight: 600;
}

/* Mallory */
@font-face {
  font-family: "Mallory";
  src: url(../assets/fonts/mallory/Mallory-Light.otf) format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Mallory";
  src: url(../assets/fonts/mallory/Mallory-LightItalic.otf) format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Mallory";
  src: url(../assets/fonts/mallory/Mallory-Medium.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Mallory";
  src: url(../assets/fonts/mallory/Mallory-Book.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Mallory";
  src: url(../assets/fonts/mallory/Mallory-BookItalic.otf) format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Mallory";
  src: url(../assets/fonts/mallory/Mallory-Bold.otf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Mallory";
  src: url(../assets/fonts/mallory/Mallory-BoldItalic.otf) format("opentype");
  font-weight: 700;
  font-style: italic;
}